import './App.css';
import Navbar from '../NavBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../pages';
import Contact from '../Components/Contact';
import Courses from '../pages/courses';
import LocateUs from '../Components/LocateUs/LocateUs';
import Achievements from '../pages/achievements';
import Alumni from '../pages/alumni';
import Gallery from '../pages/gallery';
import { MDBFooter } from 'mdb-react-ui-kit';


function App() {
  return (
    <div className="App bg-white" >
      <div className="content">
      <Contact />
      <Router>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/courses' element={<Courses />} />
        <Route exact path='/gallery' element={<Gallery />} />
        <Route exact path='/achievements' element={<Achievements />} />
        <Route exact path='/alumni' element={<Alumni />} />
      </Routes>
    </Router>
    <LocateUs />
    <MDBFooter className='bg-footercolor text-center text-lg-left'>
      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className='text-dark' href='https://mdbootstrap.com/'>
          Sri Kalaimagal
        </a>
      </div>
    </MDBFooter>
      </div>
    </div>
  );
}

export default App;
