import React from 'react';
import ContactUsBanner from '../Components/Contact/ContactUsBanner';
import HomeContent from '../Components/HomeContent/HomeContent';
import { Title } from '../Title/Title'

const Home = () => {
  return (
    <div>
        <div className="container">
        <ol class="breadcrumb mb-0 bg-white">
          <li class="breadcrumb-item active" aria-current="page"> Home </li>
        </ol>
        </div>
      <div> <Title /> </div>
      <div className="bg-gradient-to-r from-template-5 to-template-6 text-template-4"><ContactUsBanner /> </div>
      <div className="container">
        <HomeContent />
      </div>
    </div>

  );
};

export default Home;
