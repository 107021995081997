import React from 'react';
import CoursesContent from '../Components/CoursesContent/CoursesContent';

const Courses = () => {
    return (
        <div className="container">
                <ol class="breadcrumb mb-0 bg-template-3">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Courses</li>
                </ol>
                < CoursesContent/>
        </div>
        
    );
};

export default Courses;
