import React from 'react'
import AlumniContent from '../Components/AlumniContent/AlumniContent'

export default function Alumni() {
  return (
    <div className="container">
                <ol class="breadcrumb mb-0 bg-template-3">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Alumni testimonials</li>
                </ol>
                <AlumniContent />
    </div>
  )
}
