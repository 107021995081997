import React, { Component } from "react";
import AOS from "aos";
import GovernmentCertifiedCourses from "./GovernmentCertifiedCourses";
import MiscCourses from "./MiscCourses";
import VideoContent from "./VideoContent";

export default class CoursesContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {
        background:
          "transparent linear-gradient(90deg,#fff 0,#e7cffc 100%) 0 0 no-repeat padding-box",
        "border-radius": "81px",
        padding: "14px 138px 14px 0",
        position: "relative",
      },
    };
  }
  

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div className="container text-justify pt-3 pb-3" >
          <VideoContent />
          <div data-aos="fade-up" >
        <p className="h4 text-textheader-1" style={this.state.style}>
          Sri Kalaimagal courses:
        </p>
        <p className="text-textcontent-1">
          Tabulated below are the different courses list for various classes
          offered by the group of Kalaimagal institutions
        </p>
        </div>
        <GovernmentCertifiedCourses/>
        <MiscCourses />
      </div>
    );
  }
}
