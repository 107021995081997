import React from 'react';
import { Link } from "react-router-dom";
import './index.css';

export default function Navbar() {
    return (
        <nav class="navbar stick-ontop navbar-expand-lg navbar-light bg-light z-10 ">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <Link to={"/"} className="nav-link navbar-brand"> Sri Kalaimagal </Link>
      
        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item active">
              <Link to={"/"} className="nav-link"> Home <span class="sr-only">(current)</span></Link>
            </li>
            <li class="nav-item">
              <Link to={"/courses"} className="nav-link"> Courses </Link>
            </li>
            <li class="nav-item">
              <Link to={"/achievements"} className="nav-link"> Our achievements </Link>
            </li>
            <li class="nav-item">
              <Link to={"/alumni"} className="nav-link"> Alumni testimonials </Link>
            </li>
          </ul>
        </div>
      </nav>
    )
}
