import React from 'react'
import AchievementsContent from '../Components/AchievementsContent/AchievementsContent'

export default function Achievements() {
  return (
    <div className="container">
                <ol class="breadcrumb mb-0 bg-template-3">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Achievements</li>
                </ol>
                <AchievementsContent />
    </div>

  )
}
