import React from 'react';

const Gallery = () => {
    return (
        <div className="container">
                <ol class="breadcrumb mb-0 bg-template-3">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Gallery</li>
                </ol>
        </div>

    );
};

export default Gallery;
