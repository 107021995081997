import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default function Contact() {
  return (
    <div className="alert mb-0 bg-gradient-to-r from-template-5 to-template-6 text-template-4 container-fluid h-full top-0">
      <div className="container">
      <div className="row">
        <div className="col">
          <FontAwesomeIcon icon={faPhone} />
          <span className="margin-left-md"> +91 9344255380 </span>
        </div>
        <div className="col text-right">
          <FontAwesomeIcon icon={faEnvelope} />
          <span className="margin-left-md"> srikalaimagal1978@gmail.com </span>
        </div>
      </div>
      </div>
    </div>
  )
}
