import React, { Component } from "react";
import AOS from "aos";
import VideoContent from "./VideoContent";

export default class AchievementsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {
        background:
          "transparent linear-gradient(90deg,#fff 0,#e7cffc 100%) 0 0 no-repeat padding-box",
        "border-radius": "81px",
        padding: "14px 138px 14px 0",
        position: "relative",
      },
    };
  }

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div className="container text-justify pt-3 pb-3" data-aos="fade-up">
          <VideoContent />
        <div className="pb-3">
          <p className="h1 text-textheader-1" style={this.state.style}>
            Achievements
          </p>
          <p className="text-textcontent-1">
            Sri Kalaimagal Institute was started with the main intention of educating the people and make them job ready. We are produ to say we have placed more than 1000's of students all these years. It is just the beginning and we will be achieving more and more. We are known for producing one of the most quality Tally and typewriting courses in whole of Tamilnadu. We are proud to say we have achieved a lot and we have listed few below
          </p>
        </div>

        <div className="pb-3">
          <div className="h4 pt-2 text-textheader-1" style={this.state.style}>
          Typewriting
          </div>
          <p className="text-textcontent-1">
            Established - 1976 <br />
            Approved by DOTE - 1978 (Permanent Approval)<br />
            <div className="h6 pt-3" >Result details </div> 
            <p> - 24 times of 100% result </p>
            <p> - 54 times of 90% result and above </p>
            <p> - Lowest ever is 87% </p>
          </p>
        </div>

        <div className="pb-3">
          <div className="h4 pt-2 text-textheader-1" style={this.state.style}>
          Computer (COA)
          </div>
          <p className="text-textcontent-1">
            Established - 2002 <br />
            <div className="h6 pt-3" >Result details </div> 
            <p> - 38 times of 100% result </p>
            <p> - 4 times of 96% result and above </p>
          </p>
        </div>

        <div className="pb-3">
          <div className="h4 pt-2 text-textheader-1" style={this.state.style}>
          Shorthand
          </div>
          <p className="text-textcontent-1">
            Established - 1990 <br />
            Approved by DOTE - 1995 (Permanent Aproval) <br />
            <div className="h6 pt-3" >Result details </div> 
            <p> - 5 times of 100% result </p>
            <p> - 40 times of 74% result and above </p>
            <p> - Lowest ever is 62% </p>
          </p>
        </div>

        <div className="pb-3">
          <div className="h4 pt-2 text-textheader-1" style={this.state.style}>
          Tally courses
          </div>
          <p className="text-textcontent-1">
            Established - 2015 <br />
            Approved by Tally Education - 2017 (Permanent Aproval) <br />
            <div className="h6 pt-3" >Result details </div> 
            <p> - 100% result </p>
          </p>
        </div>
        
      </div>
    );
  }
}
