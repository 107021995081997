import React, { Component } from "react";
import AOS from "aos";

export default class GovernmentCertifiedCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      col1_style: { width: "16.66%" },
      col2_style: { width: "35.34%" },
      col3_style: { width: "48.00%" },
    };
  }

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div data-aos="zoom-in">
        <div className="h5 pt-2 text-textheader-1">
          Government certified courses:
        </div>
        <div data-aos="fade-left">
          <div className="h6 pt-2 text-textheader-1">Type writing:</div>
          <div className="text-textcontent-1">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" style={this.state.col1_style}>
                    #
                  </th>
                  <th scope="col" style={this.state.col2_style}>
                    Language
                  </th>
                  <th scope="col" style={this.state.col3_style}>
                    Level
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>English</td>
                  <td>Pre Junior</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>English</td>
                  <td>Junior</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>English</td>
                  <td>Senior</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>Tamil</td>
                  <td>Pre Junior</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td>Tamil</td>
                  <td>Junior</td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td>Tamil</td>
                  <td>Senior</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div data-aos="fade-left">
          <div className="h6 pt-2 text-textheader-1">Computer:</div>
          <div className="text-textcontent-1">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" style={this.state.col1_style}>
                    #
                  </th>
                  <th scope="col" style={this.state.col2_style}>
                    Field
                  </th>
                  <th scope="col" style={this.state.col3_style}>
                    Course
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Computer</td>
                  <td>Office Automation</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div data-aos="fade-left">
          <div className="h6 pt-2 text-textheader-1">Shorthand courses:</div>
          <div className="text-textcontent-1">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" style={this.state.col1_style}>
                    #
                  </th>
                  <th scope="col" style={this.state.col2_style}>
                    Language
                  </th>
                  <th scope="col" style={this.state.col3_style}>
                    Level
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>English</td>
                  <td>Junior</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>English</td>
                  <td>Intermediate</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>English</td>
                  <td>Senior</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>Tamil</td>
                  <td>Junior</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td>Tamil</td>
                  <td>Intermediate</td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td>Tamil</td>
                  <td>Senior</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div data-aos="fade-left">
          <div className="h6 pt-2 text-textheader-1">Accountancy courses:</div>
          <div className="text-textcontent-1">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" style={this.state.col1_style}>
                    #
                  </th>
                  <th scope="col">Level</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Junior</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Senior</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
