import React from 'react'

export default function ContactUsBanner() {
  function openTawk() {
    window.Tawk_API.toggle()
  }
  return (
    <div className="container p-1 d-flex justify-content-center">
        <div className="row align-self-center">
                <div className="col-12  font-bold tracking-wide">
                Trusted & Best Authorized Tally Partner for your business! Tally Prime Partner!
                <button onClick={openTawk} className="ml-2 p-2 bg-template-4 text-green-900 hover:text-white hover:bg-template-5 rounded-md  hover:scale-110 duration-300 border-2 "> Contact us </button>
                </div>
        </div>
    </div>
  )
}
