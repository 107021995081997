import React, { Component } from "react";
import AOS from "aos";

export default class HomeContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {
        background:
          "transparent linear-gradient(90deg,#fff 0,#e7cffc 100%) 0 0 no-repeat padding-box",
        "border-radius": "81px",
        padding: "14px 138px 14px 0",
        position: "relative"
      },
      open: true
    };
  }

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div className="container text-justify pt-3 pb-3" data-aos="fade-up">
        <div className="pb-3">
          <p className="h1 text-textheader-1" style={this.state.style}>
            About us:
          </p>
          <p className="text-textcontent-1">
            Sri Kalaimagal Institute was started as a Typing Institute in 1978
            in Mohanur which was 45 years ago. Then in 1998 we had introduced
            the Computer Basics Course. Now we have expanded our education
            institutes across Namakal, Karur, Trichy and diversified our courses
            provided not limited to Typewriting and Computer Basics but
            Shorthand, Accountancy and Tally ERP. We are proud to say that
            throughout the last 45 years we have placed more than 1000 students
            in government jobs with the type of skills and knowledge we had
            imparted. Through educating our students with Tally ERP-9 with GST
            we had successful placed more than 1000 students in private sector.
            We guide students in "Skill Training" for them to achieve their
            dream of getting into either private sector or into government
            sector jobs. In future we have planned to introduce a 3 years course
            of "Integrated Career Development", where we impart our students
            with 14 in demand skills training and certificate which will help
            them achieve their dream of getting the perfect job. Also to train
            them to crack government exams like TNPSC Group II, IIA, IV and
            assist them in getting government jobs.
          </p>
        </div>
        <div className="pb-3">
          <div className="h3 pt-2 text-textheader-1" style={this.state.style}>
            Our Motto:
          </div>
          <p className="text-textcontent-1">
            Good Education and valuable Certification
          </p>
        </div>
        <div className="pb-3">
          <div className="h3 pt-2 text-textheader-1" style={this.state.style}>
            Our Achievements:
          </div>
          <p className="text-textcontent-1">
            Got 10 times State First Rank in Typewriting Examination No other
            institutions have achieved this
          </p>
        </div>

        <div className="h3 pt-2 text-textheader-1" style={this.state.style}>
            Our Team:
          </div>

          <p className="text-textcontent-1">
            <ul class="list-group">
              <li class="list-group-item">Muralidharan K - Chairman and Founder</li>
              <li class="list-group-item">Vamshidharan S M - Managing Director</li>
              <li class="list-group-item">Murugesan (Velur) - Partner </li>
              <li class="list-group-item">Chandarasekaran (Thottiyam, Musiri) - Partner </li>
              <li class="list-group-item">Mohan (Kattuputhur) - Partner </li>
            </ul>
          </p>
      </div>
    );
  }
}