import React, { Component } from "react";
import AOS from "aos";

export default class AlumniContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {
        background:
          "transparent linear-gradient(90deg,#fff 0,#e7cffc 100%) 0 0 no-repeat padding-box",
        "border-radius": "81px",
        padding: "14px 138px 14px 0",
        position: "relative",
      },
    };
  }

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div className="container text-justify pt-3 pb-3" data-aos="fade-up">
        <div className="pb-3">
          <p className="h1 text-textheader-1" style={this.state.style}>
            Testimonials:
          </p>
          <p className="text-textcontent-1">
            There have been more than 1000's of alumnis graduated from our
            institutions and they are doing a great job in their work as well.
            Listing very few testimonials of some of our alumnis who are doing
            great work.
          </p>
        </div>
        <div className="pb-3">
          <div className="h5 pt-2 text-textheader-1" style={this.state.style}>
            SOWMIYA M:
          </div>
          <p className="text-textcontent-1">
            The way of teaching was good. If we had any doubts, you had given a
            clear explanation for it. It will be a progress for our studies.
            Keep doing your good deeds.
          </p>
        </div>

        <div className="pb-3">
          <div className="h5 pt-2 text-textheader-1" style={this.state.style}>
            SANGEETHA R:
          </div>
          <p className="text-textcontent-1">
          Taking lessons and approaching of faculty was very good. They are very engaging. And sir’s motivating speech was very useful
          </p>
        </div>

        <div className="pb-3">
          <div className="h5 pt-2 text-textheader-1" style={this.state.style}>
          SUJATHA A:
          </div>
          <p className="text-textcontent-1">
          The coaching class conducted in the morning was suitable to us. It is little difficult for me to do tamil Shorthand since I am from English medium. I learned much by this class.
          </p>
        </div>

        <div className="pb-3">
          <div className="h5 pt-2 text-textheader-1" style={this.state.style}>
          KANIMOZHI P:
          </div>
          <p className="text-textcontent-1">
            This institute compare other institute good teaching methods and tests are properly conducted every week
          </p>
        </div>

        <div className="pb-3">
          <div className="h5 pt-2 text-textheader-1" style={this.state.style}>
            PREMKUMAR P:
          </div>
          <p className="text-textcontent-1">
          All are good in this institution. Approach of the faculty is excellent. Coaching of the institution is excellent. Weekly test were conducted properly. Test papers valued properly. Everything here is great.
          </p>
        </div>

        <div className="pb-3">
          <div className="h5 pt-2 text-textheader-1" style={this.state.style}>
             SHRI VAASANAA S:
          </div>
          <p className="text-textcontent-1">
          I am new bud to this flower garden. The staffs who encourage and guide the students here. Most Sunday classes helps the person who is lacking   behind can improve in the upcoming tests or exam. My conclusion was, this institute was excellent and also I am adapted to this atmosphere.
          </p>
        </div>
      </div>
    );
  }
}
