import React, { Component } from "react";
import AOS from "aos";

export default class MiscCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      col1_style: { width: "16.66%" },
      col2_style: { width: "35.34%" },
      col3_style: { width: "48.00%" },
    };
  }

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div>
        <div className="h5 pt-2 text-textheader-1" data-aos="zoom-in-left">
          Other courses:
        </div>
        <div data-aos="fade-left">
          <div className="h6 pt-2 text-textheader-1">Computer courses:</div>
          <div className="text-textcontent-1">
            <ul class="list-group">
              <li class="list-group-item">DCS</li>
              <li class="list-group-item">DCA</li>
              <li class="list-group-item">D.com</li>
              <li class="list-group-item">HDCA</li>
              <li class="list-group-item">PGDCA</li>
              <li class="list-group-item">C</li>
              <li class="list-group-item">C++</li>
              <li class="list-group-item">Java</li>
              <li class="list-group-item">DTP</li>
            </ul>
          </div>
        </div>

        <div data-aos="fade-left">
          <div className="h6 pt-2 text-textheader-1">Tally courses:</div>
          <div className="text-textcontent-1">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" style={this.state.col1_style}>
                    #
                  </th>
                  <th scope="col" style={this.state.col2_style}>
                    Course
                  </th>
                  <th scope="col" style={this.state.col3_style}>
                    Certification
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Tally</td>
                  <td>ERP 9</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Tally Prime</td>
                  <td>Level 1</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Tally Prime</td>
                  <td>Level 2</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>Tally Prime</td>
                  <td>Level 3</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td>Tally Prime</td>
                  <td>Comprehensive</td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td>Tally Prime</td>
                  <td>Professional</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
