import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class VideoContent extends Component {
    render () {
        return (
        <div className='container player-wrapper center'>
            <ReactPlayer
            className='react-player'
            url= 'videos/achievements_video.mp4'
            width='100%'
            height='75%'
            controls = {true}
            playing = {true}
            />
        </div>
        )
    }
}

export default VideoContent;