import React, { Component } from "react";
import LocateUsCard from "./LocateUsCard";

export default class LocateUs extends Component {
  render() {
    return (
      <div className="bg-gradient-to-r from-template-5 to-template-6">
      <div className="container">
        <div className="font-extrabold d-flex justify-content-center mb-4 pt-3 font-mono subpixel-antialiased text-xl text-template-4">
          Locate us
        </div>
        <div className="row justify-content-around">
          <LocateUsCard url="https://www.google.com/maps?q=11.0612746,78.1388561&output=embed" title="Mohanur" address="Police station opposite, Mohanur, Namakkal - 637015" contact="9043855380" />
          <LocateUsCard url="https://www.google.com/maps?q=11.2150154,78.1679769&output=embed" title="Namakkal" address="Canara bank upstair, Mohanur, Namakkal - 637015" contact="7305008056" />
          <LocateUsCard url="https://www.google.com/maps?q=10.9593237,78.0770532&output=embed" title="Karur" address="1st floor Jawahar bazaar, Near Tip-Top, Karur - 639001" contact="9884380903" />
          <LocateUsCard url="https://www.google.com/maps?q=10.9593237,78.0770532&output=embed" title="Paramathi Velur" address="Need to update! Old madhura hotel, High school road, P.Velur - 638182" contact="9080134884" />
          <LocateUsCard url="https://www.google.com/maps?q=10.988113,78.336413&output=embed" title="Thottiyam" address="Madhura super market, Pavalakadai Veethi, Thottiyam - 621215" contact="9047163393" />
          <LocateUsCard url="https://www.google.com/maps?q=10.988113,78.336413&output=embed" title="Kattuputhur" address="NEED TO UPDATE! Near to Saravana Medical, Mohanur road, Kattuputhur - 621207" contact="8508605115" />
          <LocateUsCard url="https://www.google.com/maps?q=10.953252763582519,78.44375610351562&output=embed" title="Musiri" address="UPdate address! 81/2, Saradha mill road, Podanur, Coimbatore - 641023" contact="9047163393" />
          <LocateUsCard url="https://www.google.com/maps?q=11.3606659,77.944971&output=embed" title="Vivekananda clg, Tiruchengode" address="Vivekanandha Women's college Campus, Tiruchengode - 637211" contact="9025805882" />
        </div>
      </div>
      </div>
    );
  }
}
