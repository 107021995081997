import React from 'react'

export default function LocateUsCard({url, title, address, contact}) {
  return (
    <div class="card hover:-translate-y-1 hover:scale-110 duration-300 border-template-2 border-4 border-solid hover:border-double m-1 shadow p-1 bg-template-3 rounded text-center col-md-3">
    <h5 class="card-title ">  <span className="font-semibold"> Location : </span> {title}</h5>
    <ul class="list-group list-group-flush bg-template-contrast">
      <li class="list-group-item bg-template-contrast"> <span className="font-semibold ">  Address: </span> {address}</li>
      <li class="list-group-item bg-template-contrast"> <span className="font-semibold"> Contact: </span> {contact}</li>
      <iframe title="gmap"
        src={url}
        frameborder="2"
        width="100%" height="100%" 
        allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </ul>
  </div>
)
}
